import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/awards/standard-graphics"

    return (
        <Layout pageTitle="Standard Graphics">
            <Row>
                <Col>
                    <Link to="/awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                    <p className="para">
                        Get even more eyes on your business by sharing and
                        encouraging your network to get involved in this year's
                        Women's Busines Awards!
                    </p>
                    <p className="para">
                        Elevate your status as an award nominee and an AWARD WINNER!
                    </p>
        
                    
                </Col>
            </Row>
            <ImageDownload
                root={root}
                name="General Awards Promo"
                filename="awards-promo"
                text="Promo graphics for your use. You can use and adapt the marketing copy below!"
                squareHref="https://www.canva.com/design/DAFRjMlMpRk/_NUqW1OxxQOwcvq_McvZcg/view?utm_content=DAFRjMlMpRk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFRXfJgDLk/kfmbGH6Obmd-NEET9w0_5g/view?utm_content=DAFRXfJgDLk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/10puqRIqzuL1TfqG1yAitHJGZhCpBrmJrJVIRgRuz6AQ/edit?usp=sharing"
            />
        </Layout>
    )
}

export default IndexPage
